<template>
<!-- eslint-disable max-len -->
  <div class="dashboard row">

    <!-- Sidebar -->
    <nav class="col-lg-3 col-xl-2 d-flex flex-column sidebar" :class="{'sidebar-expanded': sideNavToggleStatus, 'sidebar-closed': !sideNavToggleStatus}">
      <h1 class="py-3 pl-3">
        <img class="img-fluid logo" :src="logo" />
      </h1>
      <router-link
        :to="{ name: 'Dashboard' }"
        :class="linkClasses">
        <span
          :class="linkIconClasses">
          home
        </span>
        <span
          class="link-text"
          :class="linkTextClasses">
          Dashboard
        </span>
      </router-link>
      <!-- <router-link
        :to="{ name: 'DashboardEvents' }"
        :class="linkClasses">
        <span
          :class="linkIconClasses">
          event
        </span>
        <span
          class="link-text"
          :class="linkTextClasses">
          Events
        </span>
      </router-link> -->
      <router-link
        :to="{ name: 'DashboardExperiences' }"
        :class="linkClasses">
        <span
          :class="linkIconClasses">
          date_range
        </span>
        <span
          class="link-text"
          :class="linkTextClasses">
          Experiences
        </span>
      </router-link>
      <router-link
        :to="{ name: 'DashboardOrders' }"
        :class="linkClasses">
        <span
          :class="linkIconClasses">
          confirmation_number
        </span>
        <span
          class="link-text"
          :class="linkTextClasses">
          Orders
        </span>
      </router-link>
      <router-link
        :to="{ name: 'StripePayments' }"
        :class="linkClasses">
        <span
          :class="linkIconClasses">
          credit_card
        </span>
        <span
          class="link-text"
          :class="linkTextClasses">
          Payments
        </span>
      </router-link>
      <hr />
      <template v-if="isStaff">
        <router-link
          :to="{ name: 'DashboardAccessRequests' }"
          :class="linkClasses"
        >
          <span
            :class="linkIconClasses">
            lock
          </span>
          <span
            class="link-text"
            :class="linkTextClasses">
            Access Requests
          </span>
        </router-link>
        <hr />
      </template>
      <router-link
        :to="{ name: 'DashboardSettings' }"
        :class="linkClasses">
        <span
          :class="linkIconClasses">
          settings
        </span>
        <span
          class="link-text"
          :class="linkTextClasses">
          Settings
        </span>
      </router-link>
      <a
        @click="logOut"
        :class="linkClasses">
        <span
          :class="linkIconClasses">
          power_settings_new
        </span>
        <span
          class="link-text"
          :class="linkTextClasses">
          Logout
        </span>
      </a>
    </nav>
    <!-- :class="{ 'col-sm-4': sideNavToggleStatus, 'hidden': !sideNavToggleStatus }" -->

    <!-- :class="{ 'col-sm-6': sideNavToggleStatus, 'col-sm-12': !sideNavToggleStatus}" -->
    <!-- Content -->
    <div class="col-lg-9 col-xl-10 content" :class="{'sidebar-expanded': sideNavToggleStatus, 'sidebar-closed': !sideNavToggleStatus}" @click="hideSideNav">

      <!-- Navigation Header -->
      <div class="row nav-header pt-3">
        <div class="col-4 pl-4 py-2 d-flex align-items-center">
          <a class="bg-light text-dark d-flex align-items-center rounded p-1 toggler" v-on:click.stop @click="toggleSideNav">
            <span class="material-icons-outlined">
              menu
            </span>
          </a>
          <span class="page-title pl-4">
            {{ pageTitle }}
          </span>
        </div>
        <div class="col-8 d-flex align-items-center justify-content-end" :class="{'sidebar-expanded': sideNavToggleStatus, 'sidebar-closed': !sideNavToggleStatus}">
          <div class="input-group input-group-sm w-75 dropdown">
            <input type="text"
              class="form-control pl-3"
              placeholder="Search here"
              aria-label="Search here"
              aria-describedby="btnGroupAddon"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              data-offset="10,7"
              v-model="searchQuery"
            >
            <div class="dropdown-menu dropdown-menu"
              style="width: 95%;"
            >
              <template v-if="!searchResults || searchResults.length < 1">
                <a class="dropdown-item disabled p-0" style="font-size: 1em !important" href="#"
                  tabindex="-1" aria-disabled="true"
                >
                  Search results will appear here
                </a>
              </template>
              <template v-for="item in searchResults">
                <a class="dropdown-item text-wrap"
                  :key="item.uuid"
                  @click="goToEvent(item)"
                >
                  {{ item.title }}
                </a>
              </template>
            </div>

            <div class="input-group-append">
              <div class="input-group-text" id="btnGroupAddon">
                <template v-if="isSearchLoading">
                  <div class="spinner-border spinner-border-sm text-secondary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </template>
                <template v-else>
                  <span class="material-icons md-18">
                  search
                  </span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-2 d-flex align-items-center justify-content-around notifications">
          <a class="d-flex align-items-center py-2 px-2">
            <span class="material-icons-outlined">
            notifications
            </span>
          </a>
          <a class="d-flex align-items-center py-2 px-2">
            <span class="material-icons-outlined">
            textsms
            </span>
          </a>
          <a class="d-flex align-items-center py-0 px-0">
            <img class="rounded-circle" :src="profilePhoto" />
          </a>
        </div>
        <div class="col-2 d-flex align-items-center justify-content-center scheduled-events">
          <a class="d-flex align-items-center justify-content-between px-2 py-2">
            <span class="material-icons-outlined md-18 pr-2">
            calendar_today
            </span>
            Scheduled Events ({{ scheduledEventsCount }})
          </a>
        </div> -->
      </div>

      <!-- Breadcrumbs -->
      <div class="row breadcrumbs d-flex align-items-center text-left border-bottom">
        <div class="col pl-0">
          <ul class="list-group list-group-horizontal pl-3 d-flex align-items-center">
            <template v-for="(item, index) in breadcrumbs">
              <li
                :key="index"
                class="list-group-item border-0 pl-0 font-14"
              >
              <template v-if="item.isCurrent">
                <span
                  class="text-secondary"
                  style="text-transform: capitalize;"
                >
                  {{ item.crumb }}
                </span>
              </template>
              <template v-else>
                <router-link :to="item.path"
                  style="text-transform: capitalize;"
                >
                  {{ item.crumb }}
                </router-link>
                <span class="ml-3 font-16">
                  {{ '/' }}
                </span>
              </template>
              </li>
            </template>
          </ul>
        </div>
      </div>

      <!-- Page content -->
      <div class="page-content mr-2 ml-2 mt-3 mb-3">
        <router-view></router-view>
      </div>
    </div>

    <!-- Schedule: should be a popup/drodown -->
    <!-- <div class="col-3">
      Schedule
    </div> -->

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import * as $ from 'jquery';

export default {
  data() {
    return {
      // eslint-disable-next-line global-require
      logo: require('@/assets/images/dashboard/logo_white.png'),
      linkClasses: ['mb-2', 'd-flex', 'align-items-center'],
      linkIconClasses: ['material-icons-outlined', 'md-18', 'h-100', 'd-flex', 'align-items-center', 'justify-content-center', 'w-20', 'px-2'],
      linkTextClasses: ['pl-4', 'w-80'],
      notificationIconClasses: ['d-flex', 'align-items-center', 'py-2', 'px-2'],
      searchQuery: '',
      sideNavToggleStatus: false,
      sideNavActive: false,
    };
  },

  mounted() {
    if (this.isAuthenticated) {
      this.$store.dispatch('profile/getProfile');
      this.$store.dispatch('dashboard/getDashboardData');
    }
  },

  watch: {
    searchQuery(newVal, oldVal) {
      console.debug(`${newVal}, ${oldVal}`);
      if (newVal !== oldVal) {
        const debounced = debounce(this.searchEvents, 1000);
        debounced(newVal);
      }
    },

    $route(to, from) {
      console.log(`${to}, ${from}`);
      this.sideNavToggleStatus = false;
    },
  },

  computed: {
    ...mapState('profile', {
      profilePhoto: 'avatar',
      isStaff: 'isStaff',
    }),

    ...mapGetters('auth', {
      isAuthenticated: 'isLoggedIn',
    }),

    ...mapState('events', {
      isSearchLoading: 'isSearchLoading',
      searchResults: 'searchResults',
    }),

    pageTitle() {
      return this.$route.path.name || 'WHAM';
    },

    breadcrumbs() {
      const crumbs = [];
      const regexes = [];
      this.$route.matched.slice(1).forEach((c) => {
        const isCurrent = c.name === this.$route.name;
        const regex = c.regex.toString();
        const position = regexes.indexOf(regex);
        if (position < 0) {
          crumbs.push({
            crumb: c.meta.crumb,
            path: c.path,
            isCurrent,
          });
        } else {
          if (isCurrent) {
            crumbs[position].isCurrent = isCurrent;
          }
          if (!crumbs[position].crumb && c.meta.crumb) {
            crumbs[position] = {
              crumb: c.meta.crumb,
              path: c.path,
              isCurrent,
            };
          }
        }
        regexes.push(regex);
      });
      return crumbs;
    },

    scheduledEventsCount() {
      let value = 0;
      if (this.isExperienceView) {
        value = this.$store.getters['dashboard/upcomingExperiences'];
      } else {
        value = this.$store.getters['dashboard/upcomingEvents'];
      }
      return value;
    },

    isExperienceView() {
      return this.$route.meta.event_type === 'experience';
    },

    pageContentOverflow() {
      return this.$route.name === 'DashboardEvents' ? 'hidden' : 'scroll';
    },
  },

  methods: {
    logOut() {
      console.debug('logging out');
      this.$store.dispatch('auth/logout')
        .then((response) => {
          if (response.success) {
            this.$router.push('/login');
          }
          return response;
        });
    },

    goToEvent(event) {
      $().dropdown('hide');
      const name = event.event_type === 'experience'
        ? 'DashboardExperienceDetail' : 'DashboardEventDetail';
      this.$router.push({
        name,
        params: {
          eventId: event.uuid,
        },
      });
    },

    searchEvents(searchQuery) {
      return this.$store.dispatch(
        'events/searchEvents',
        {
          searchQuery,
          pageSize: 15,
        },
      );
    },

    hideSideNav(e) {
      e.preventDefault();
      if (this.sideNavToggleStatus === true) {
        console.log(`Side nav body click toggled to:: ${!this.sideNavToggleStatus} ${e}`);
        this.sideNavToggleStatus = !this.sideNavToggleStatus;
      }
    },

    toggleSideNav(e) {
      e.preventDefault();
      if (this.sideNavToggleStatus === false) {
        console.log(`Side nav toggler click toggled to:: ${!this.sideNavToggleStatus}`);
        this.sideNavToggleStatus = !this.sideNavToggleStatus;
      }
    },
  },
};
</script>

<style lang="scss">
$link-hover-color: #d4c5c567;

.dashboard {
  background-color: $wham-primary-light-color;
  min-height: 100vh;

  .sidebar {
    color: $wham-sec-light-color;
    background-color: $wham-primary-color;
    width: 30%;
    transition: transform 3s cubic-bezier(.4,0,.2,1);
    overflow: hidden;
    min-height: 100vh;
    position: fixed;

    @media (min-width: 995px) {
      z-index: 10;
    }

    @media (max-width: 994px) {

      width: 40%;
      transition: transform 3s cubic-bezier(.4,0,.2,1);

      &.sidebar-expanded {
        z-index: 10;
        position: absolute;
      }

      &.sidebar-closed {
        z-index: -10;
        // position: fixed;
      }
    }

    @media (max-width: 800px) {
      width: 50%;
    }

    @media (max-width: 600px) {
      width: 60%;
    }

    .logo {
      max-width: 150px;
    }

    hr {
      border-color: $wham-sec-light-color;
      width: 100%;
    }
    a {
        color: $wham-sec-light-color;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
    }

    a:hover,
    .active,
    .router-link-active,
    .router-link-exact-active {
      text-decoration: none;
      background-color: $link-hover-color;
      border-radius: 20px;
      font-weight: 600;

      .material-icons-outlined {
        color: $wham-primary-color;
        background-color: $wham-sec-light-color;
        border-radius: 100%;
        font-size: 24px;
        padding: 10px;
      }
    }

    .link-text {
      padding-top: .75rem;
      padding-bottom: .75rem;
    }
  }

  .content {
    background-color: $wham-primary-light-color;
    margin-left: auto;

    @media (max-width: 994px) {

      &.sidebar-expanded {
        background-color: rgba(235, 235, 235, .3);
        content: '';
        filter: blur(5px);
        inset: 3%;
        overflow: hidden;
        position: fixed;
      }
    }
  }

  /* Named so as not to mix with bootstraps navbar class */
  .nav-header {
    @extend .content;
    height: 10vh;

    .toggler {
      z-index: 20;

      cursor: pointer;
      -webkit-user-select: none;

      .material-icons-outlined{
        font-size: 2em;
      }
    }

    .material-icons-outlined {
      font-size: 18px;
    }

    .page-title {
      font-weight: bold;
    }

    a:hover {
      text-decoration: none;
      cursor: pointer;
    }

    .input-group {
      input {
        border-radius: 20px;
        border-right: none;
      }

      .input-group-text {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: inherit;
      }

      span {
        color: rgb(209, 191, 191);
      }

      span:hover {
        color: black;
      }

      .input-group-append {
        cursor: pointer
      }
    }

    .notifications {
      a {
        border-radius: 50%;
        background-color: $wham-icon-bg-color;
        color: $wham-blue-color;
      }

      img {
        width: 34px;
        height: 34px;
        object-fit: cover;
      }
    }

    .scheduled-events a {
      border: 1px solid $wham-bold-color;
      border-radius: 20px;
      font-size: 10px;
      font-weight: bold;
      color: $wham-bold-color;
    }
  }

  .breadcrumbs {
    @extend .content;

    height: 7vh;
    font-size: 12px;
    border-bottom: 1px solid;
  }

  .page-content {
    @extend .content;
  }
}

</style>
